import request from '../request'


// 分页查询
export function pageQuery(data) {
	return request({
		url: '/uc/activity/page-query',
		method: 'POST',
		data: data
	})
}


// 分页查询
export function getmyorders(data) {
	return request({
		url: '/uc/activity/getmyorders',
		method: 'POST',
		data: data
	})
}


// 分页查询
export function getmyminings(data) {
	return request({
		url: '/uc/miningorder/my-minings',
		method: 'POST',
		data: data
	})
}


// 分页查询
export function getmylocked(data) {
	return request({
		url: '/uc/lockedorder/my-locked',
		method: 'POST',
		data: data
	})
}

// 创新实验室详情
export function activityDetail(data) {
	return request({
		url: '/uc/activity/detail',
		method: 'POST',
		data: data
	})
}


// 根据活动id获取用户记录
export function getmemberrecords(data) {
	return request({
		url: '/uc/activity/getmemberrecords',
		method: 'POST',
		data: data
	})
}



// 提币验证码
export function activityCode(data) {
	return request({
		url: '/uc/mobile/activity/code',
		method: 'POST',
		data: data
	})
}


// 参与活动
export function activityAttend(data) {
	return request({
		url: '/uc/activity/attend',
		method: 'POST',
		data: data
	})
}